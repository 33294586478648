<template>
  <div>
    <Frame title="Compañía contratante" :loading="loading">
      <Grid
        :data="data"
        :columns="columns"
        :actions="actions"
        addText="Agregar compañía contratante"
        :showAdd="$can('AddEmployerCompany', 'STM')"
        @addNew="addNew"
        @reloadData="getEmployerCompanies"
      />
      <Modal :active.sync="openModal">
        <EmployerCompanyForm :modelToEdit="modelToEdit" :readonly="readonly" />
      </Modal>
    </Frame>
  </div>
</template>

<script>
// import ApiClient from "./../../../utils/ApiClient";
import EmployerCompanyForm from './EmployerCompanyForm.vue';

export default {
  name: 'EmployerCompany',
  components: {
    EmployerCompanyForm,
  },
  data() {
    return {
      data: [],
      openModal: false,
      loading: false,
      modelToEdit: null,
      actions: [
        {
          text: 'Ver',
          callback: this.onView,
        },
      ],
      columns: [
        {
          title: 'RFC',
          field: 'code',
          searchable: true,
        },
        {
          title: 'Denominación',
          field: 'name',
          searchable: true,
        },
        {
          title: 'Régimen',
          field: 'regimenFiscalDescription',
        },
        {
          title: 'Activo',
          field: 'isActive',
          template: this.templateActive,
        },
      ],
      readonly: false,
    };
  },
  beforeMount() {
    if (this.$can('EditEmployerCompany', 'STM'))
      this.actions.push({
        text: 'Editar',
        callback: this.onEdit,
      });

    if (this.$can('DeleteEmployerCompany', 'STM'))
      this.actions.push({
        text: 'Eliminar',
        callback: this.deleteEmployerCompany,
      });
  },
  methods: {
    onView(item) {
      this.readonly = true;
      this.modelToEdit = {
        EmployerCompanyID: item.id,
        Code: item.code,
        LegalName: item.name,
        Description: item.description,
        FKC_RegimenFiscal: item.pkC_RegimenFiscal,
        IsActive: item.isActive,
      };
      this.openModal = true;
    },
    onEdit(item) {
      this.readonly = false;
      this.modelToEdit = {
        EmployerCompanyID: item.id,
        Code: item.code,
        LegalName: item.name,
        Description: item.description,
        FKC_RegimenFiscal: item.pkC_RegimenFiscal,
        IsActive: item.isActive,
      };
      this.openModal = true;
    },
    addNew() {
      this.readonly = false;
      this.modelToEdit = null;
      this.openModal = true;
    },
    deleteEmployerCompany(item) {
      this.deleteItem(
        'EmployerCompany',
        item.id,
        '¿Desea eliminar la compañía contratante?',
        'Al eliminar la compañía contratante, no podra ser utilizada.',
        'stamp'
      );
    },
    getEmployerCompanies() {
      this.loading = true;

      this.getAll('EmployerCompany', '', 'stamp').then((data) => {
        this.data = data;
        this.loading = false;
      });
    },
    templateActive(item) {
      return item.isActive ? 'Sí' : 'No';
    },
    indexOf(array, obj) {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === obj) {
          return i;
        }
      }
      return -1;
    },
  },
  mounted() {
    this.getEmployerCompanies();
  },
};
</script>
