<template>
  <div>
    <Frame title="Compañía contratante" :titleAction="titleAction">
      <Title title="Datos generales" />

      <Layout>
        <Column size="6">
          <Field label="RFC">
            <Input
              :required="true"
              :readonly="modelToEdit"
              name="RFC"
              textTransform="uppercase"
              v-model="model.Code"
              :rules="{
                required: true,
                regex:
                  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
              }"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field label="Denominación / Razón Social">
            <Input
              :required="true"
              :readonly="readOnly"
              name="Name"
              v-model="model.LegalName"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field label="Nombre comercial">
            <Input
              :required="true"
              :readonly="readOnly"
              name="Description"
              v-model="model.Description"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field label="Regimen fiscal">
            <ComboBox
              :required="true"
              :disabled="readOnly"
              name="RFC"
              modelEndpoint="company/catalogs"
              api="stamp"
              groupData="regimenFiscalCatalog"
              :limitSearch="null"
              v-model="model.FKC_RegimenFiscal"
              rules="required"
            />
          </Field>
        </Column>
        <Column>
          <Field>
            <CheckBox label="Activo" name="Activo" v-model="model.IsActive" :readonly="readOnly" />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button
            @click="readonly && !readOnly ? onCancelEdit() : $parent.close()"
            color="terciary-outlined"
          >
            {{ readOnly ? 'Salir' : 'Cancelar' }}
          </Button>
          <Button
            v-if="$can('EditEmployerCompany', 'STM')"
            color="secondary"
            @click="readOnly ? onEdit() : onSave()"
          >
            {{ readOnly ? 'Editar' : modelToEdit ? 'Actualizar' : 'Guardar' }}
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'EmployerCompanyForm',
  props: {
    modelToEdit: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Agregar',
      model: {
        Code: null,
        LegalName: null,
        Description: null,
        FKC_RegimenFiscal: null,
        EmployerCompanyID: null,
        IsActive: true,
      },
      readOnly: this.readonly,
    };
  },
  mounted() {
    if (this.readonly) this.$root.$emit('changeModeFrame', 'edit');

    if (this.modelToEdit) {
      this.titleAction = this.readonly ? 'Ver' : 'Editar';
      this.model = { ...this.modelToEdit };
    }
  },
  methods: {
    onSave() {
      this.save('EmployerCompany', this.model, 'stamp', 'EmployerCompanyID').then((data) => {
        if (data) {
          this.$parent.close();
        }
      });
    },
    onUpdate() {
      this.save('EmployerCompany', this.model, 'stamp', 'Code').then((data) => {
        if (data) {
          this.$parent.close();
        }
      });
    },
    onEdit() {
      this.readOnly = false;
      this.titleAction = 'Editar';

      this.$root.$emit('changeModeFrame', 'edit');
    },
    onCancelEdit() {
      this.readOnly = true;
      this.titleAction = 'Ver';

      this.model = JSON.parse(JSON.stringify(this.modelToEdit));
      this.$root.$emit('changeModeFrame', 'view');
    },
  },
};
</script>
